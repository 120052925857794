.table {
  .avatar-group .avatar
  {
    margin-right: 4px;
  }
  .badge i{
    margin-right: 10px;
  }


}




